import React, {useState} from 'react';
import {Link} from 'gatsby';

import {useStore} from "../utils/store";
import {login, logout} from "../utils/actions";


export default React.memo(function Nav() {
    const [{auth}, dispatch] = useStore();
    const [expanded, setExpanded] = useState(false);

    return (
        <nav aria-expanded={expanded}>
            <button onClick={() => setExpanded(!expanded)}>
                {'Menu'}
            </button>
            <section>
                <Link to="/"><img src={`/logo.png`} alt=""/></Link>
                <Link to="/#">{'About'}</Link>
                <Link to="/#">{'Contact'}</Link>
            </section>
            {/* <section>
                {auth.loggedIn ?
                    <>
                        <Link to="/#">{'My Account'}</Link>
                        <a onClick={() => dispatch(logout())}>{'Log out'}</a>
                    </>
                    :
                    <a onClick={() => dispatch(login())}>{'Log in'}</a>
                }
            </section> */}
        </nav>
    );
});
