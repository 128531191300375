import React from "react";
import {Link} from "gatsby";

const Footer = () => (
    <footer className="footer">
        <section>
            <Link to="/#">{'Terms and Conditions'}</Link>
            <Link to="/#">{'Privacy Policy'}</Link>
        </section>
        {/* <section>
            <a onClick={() => {
                localStorage.removeItem('state');
                window.location = '/';
            }}>
                {'Reset State (Development Only)'}
            </a>
        </section> */}
    </footer>
);

export default Footer;
