import React from "react";

import Nav from "../components/Nav";

const Header = () => (
    <header className="header">
        <Nav/>
    </header>
);

export default Header;
